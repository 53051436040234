* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body,
input,
textarea,
button {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  color: #0E101A;
  font-size: 14px;
  line-height: 1.3;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

input::-ms-clear {
  display: none;
}

select {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  font-size: 15px;
}

a,
button {
  color: rgba(1,156,254,1);
  text-decoration: none;
}

a:focus, a:focus-visible, button:focus, button:focus-visible {
  outline: none;
}

button {
  background: transparent;
  border: 0 none;
  padding: 0;
  margin: 0;
  display: inline;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

code {
  font-size: 13px;
  font-family: 'Consolas', 'Menlo', monospace;
}

img {
  border: 0 none;
}

fieldset {
  border: 0 none;
  padding: 10px 0;
  width: 100%;
  margin: 10px auto;
}

label {
  font-weight: normal;
}

input[type=text],
input[type=search],
input[type=email],
input[type=password],
textarea {
  background: #fff;
  color: #000;
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 7px 20px 8px;
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

input:focus,
textarea:focus {
  outline: 0 none;
  color: #000;
  border-color: rgba(1,156,254,1);
}

input[type="radio"],
input[type="checkbox"] {
  width: auto;
  border: 0 none;
  border-radius: 0;
  padding: 0;
}

input[type="file"] {
  background: transparent;
  border: 0 none;
  padding: 0;
  width: auto;
  font-size: 13px;
}

input[type=text],
input[type=number],
input[type=search],
input[type=email] {
  background: #fff;
}

label span {
  color: #000;
}

.ReactVirtualized__List:focus {
  outline: 0 none;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
}

th,
td {
  text-align: left;
  font-weight: normal;
  padding: 8px 12px;
}

th {
  text-transform: uppercase;
  font-size: 12px;
  color: #555;
  cursor: default;
  white-space: nowrap;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
}

td {
  border-bottom: 1px solid #f5f5f5;
  height: 45px;
  background: #fff;
  font-size: 15px;
  font-variant-numeric: tabular-nums;
}

tbody tr:first-child td {
  border-top: 1px solid #f5f5f5;
}

td:first-child {
  border-radius: 4px 0 0 4px;
  border-left: 1px solid #f5f5f5;
}

td:last-child {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #f5f5f5;
}

tfoot td {
  background: #f5f5f5 !important;
}
@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?3b0yv4') format('truetype'),
    url('fonts/icomoon.woff?3b0yv4') format('woff'),
    url('fonts/icomoon.svg?3b0yv4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-folder-filled:before {
  content: "\e9e9";
}
.icon-folder-outlined-bold:before {
  content: "\e9ea";
}
.icon-drag-and-drop:before {
  content: "\e9e8";
}
.icon-comments-s:before {
  content: "\e9e6";
}
.icon-play-s:before {
  content: "\e9e7";
}
.icon-zoom-out:before {
  content: "\e9e5";
}
.icon-ellipsis-m:before {
  content: "\e9de";
}
.icon-shared-outline:before {
  content: "\e96d";
}
.icon-spotify:before {
  content: "\e900";
}
.icon-sptfy:before {
  content: "\e900";
}
.icon-star:before {
  content: "\e901";
}
.icon-apple:before {
  content: "\e902";
}
.icon-global:before {
  content: "\e903";
}
.icon-ellipsis:before {
  content: "\e904";
}
.icon-multichart:before {
  content: "\e905";
}
.icon-percent:before {
  content: "\e906";
}
.icon-position:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-chart-circle:before {
  content: "\e909";
}
.icon-star-outlined:before {
  content: "\e90a";
}
.icon-table:before {
  content: "\e90b";
}
.icon-barchart:before {
  content: "\e90c";
}
.icon-chart:before {
  content: "\e90d";
}
.icon-close:before {
  content: "\e90e";
}
.icon-collapse:before {
  content: "\e90f";
}
.icon-success:before {
  content: "\e910";
}
.icon-delete:before {
  content: "\e911";
}
.icon-down:before {
  content: "\e912";
}
.icon-expand:before {
  content: "\e913";
}
.icon-export:before {
  content: "\e914";
}
.icon-filter:before {
  content: "\e915";
}
.icon-heart:before {
  content: "\e916";
}
.icon-next:before {
  content: "\e917";
}
.icon-forward:before {
  content: "\e918";
}
.icon-plus:before {
  content: "\e919";
}
.icon-prev:before {
  content: "\e91a";
}
.icon-backward:before {
  content: "\e91b";
}
.icon-progress:before {
  content: "\e91c";
}
.icon-right:before {
  content: "\e91d";
}
.icon-scheduled:before {
  content: "\e91e";
}
.icon-sort:before {
  content: "\e91f";
}
.icon-star-glyph:before {
  content: "\e920";
}
.icon-star-stroke-glyph:before {
  content: "\e921";
}
.icon-up:before {
  content: "\e922";
}
.icon-arrow-diag-bottom:before {
  content: "\e923";
}
.icon-arrow-diag-top:before {
  content: "\e924";
}
.icon-arrow-down:before {
  content: "\e925";
}
.icon-arrow-left:before {
  content: "\e926";
}
.icon-arrow-right:before {
  content: "\e927";
}
.icon-arrow-up:before {
  content: "\e928";
}
.icon-calendar:before {
  content: "\e929";
}
.icon-check:before {
  content: "\e92a";
}
.icon-warning:before {
  content: "\e92b";
}
.icon-error:before {
  content: "\e92c";
}
.icon-menu:before {
  content: "\e92d";
}
.icon-cross:before {
  content: "\e92e";
}
.icon-sign-out:before {
  content: "\e92f";
}
.icon-amazon:before {
  content: "\e930";
}
.icon-settings:before {
  content: "\e931";
}
.icon-removal:before {
  content: "\e932";
}
.icon-bubbling:before {
  content: "\e933";
}
.icon-changes:before {
  content: "\e934";
}
.icon-major-moves:before {
  content: "\e935";
}
.icon-drag:before {
  content: "\e936";
}
.icon-dd-b:before {
  content: "\e937";
}
.icon-dd-l:before {
  content: "\e938";
}
.icon-new-window:before {
  content: "\e939";
}
.icon-placeholder:before {
  content: "\e93a";
}
.icon-latin:before {
  content: "\e93b";
}
.icon-add-b:before {
  content: "\e93c";
}
.icon-add-l:before {
  content: "\e93d";
}
.icon-playlisting:before {
  content: "\e93e";
}
.icon-print-digital-press:before {
  content: "\e93f";
}
.icon-radio-promotion:before {
  content: "\e940";
}
.icon-social-media:before {
  content: "\e941";
}
.icon-sync:before {
  content: "\e942";
}
.icon-tv-appearances:before {
  content: "\e943";
}
.icon-concerts-touring:before {
  content: "\e944";
}
.icon-crm:before {
  content: "\e945";
}
.icon-digital-advertising:before {
  content: "\e946";
}
.icon-in-store-marketing:before {
  content: "\e947";
}
.icon-music-videos:before {
  content: "\e948";
}
.icon-nmr:before {
  content: "\e949";
}
.icon-outdoor-advertising:before {
  content: "\e94a";
}
.icon-partner-events:before {
  content: "\e94b";
}
.icon-physical-activations:before {
  content: "\e94c";
}
.icon-calendar-new:before {
  content: "\e94d";
}
.icon-add:before {
  content: "\e94e";
}
.icon-tv-advertising:before {
  content: "\e94f";
}
.icon-social-media-medium:before {
  content: "\e950";
}
.icon-outdoor-advertising-medium:before {
  content: "\e951";
}
.icon-nmr-medium:before {
  content: "\e952";
}
.icon-tv-advertising-medium:before {
  content: "\e953";
}
.icon-tv-appearances-medium:before {
  content: "\e954";
}
.icon-digital-advertising-medium:before {
  content: "\e955";
}
.icon-partner-events-medium:before {
  content: "\e956";
}
.icon-crm-medium:before {
  content: "\e957";
}
.icon-playlisting-medium:before {
  content: "\e958";
}
.icon-concerts-touring-medium:before {
  content: "\e959";
}
.icon-physical-activations-medium:before {
  content: "\e95a";
}
.icon-in-store-marketing-medium:before {
  content: "\e95b";
}
.icon-sync-medium:before {
  content: "\e95c";
}
.icon-music-videos-medium:before {
  content: "\e95d";
}
.icon-print-digital-press-medium:before {
  content: "\e95e";
}
.icon-radio-promotion-medium:before {
  content: "\e95f";
}
.icon-archived:before {
  content: "\e960";
}
.icon-ios-prev:before {
  content: "\e961";
}
.icon-ios-next:before {
  content: "\e962";
}
.icon-arrow-right-thin:before {
  content: "\e963";
}
.icon-arrow-left-thin:before {
  content: "\e964";
}
.icon-arrow-up-thin:before {
  content: "\e965";
}
.icon-arrow-down-thin:before {
  content: "\e966";
}
.icon-info:before {
  content: "\e967";
}
.icon-ellipsis-vertical:before {
  content: "\e968";
}
.icon-collapse-field:before {
  content: "\e969";
}
.icon-time:before {
  content: "\e96a";
}
.icon-filter-lined:before {
  content: "\e96b";
}
.icon-edit-outline:before {
  content: "\e96c";
}
.icon-undo:before {
  content: "\e96e";
}
.icon-view-outline:before {
  content: "\e96f";
}
.icon-delete-outline:before {
  content: "\e970";
}
.icon-circle:before {
  content: "\e971";
}
.icon-edit:before {
  content: "\e972";
}
.icon-shared:before {
  content: "\e973";
}
.icon-view:before {
  content: "\e974";
}
.icon-autogenerate:before {
  content: "\e975";
}
.icon-expand-field:before {
  content: "\e976";
}
.icon-icon-board:before {
  content: "\e977";
}
.icon-settings-s:before {
  content: "\e978";
}
.icon-unknown-m:before {
  content: "\e979";
}
.icon-unknown:before {
  content: "\e979";
}
.icon-up-single-m:before {
  content: "\e97a";
}
.icon-no-results-l:before {
  content: "\e97b";
}
.icon-list-l:before {
  content: "\e97c";
}
.icon-ellipsis-vert-l:before {
  content: "\e97d";
}
.icon-down-single-m:before {
  content: "\e97e";
}
.icon-comparison-l:before {
  content: "\e97f";
}
.icon-unassigned-status:before {
  content: "\e980";
}
.icon-appearances:before {
  content: "\e981";
}
.icon-reject:before {
  content: "\e982";
}
.icon-approve:before {
  content: "\e983";
}
.icon-gtp-l:before {
  content: "\e984";
}
.icon-history-m:before {
  content: "\e985";
}
.icon-chart-position-m:before {
  content: "\e986";
}
.icon-link-s:before {
  content: "\e987";
}
.icon-delta-s:before {
  content: "\e988";
}
.icon-enter-s:before {
  content: "\e989";
}
.icon-apollo:before {
  content: "\e98a";
}
.icon-Copy:before {
  content: "\e98b";
}
.icon-youtube-m:before {
  content: "\e98c";
}
.icon-youtube:before {
  content: "\e98c";
}
.icon-play-l:before {
  content: "\e98d";
}
.icon-play-m:before {
  content: "\e98e";
}
.icon-youtube-l:before {
  content: "\e98f";
}
.icon-blocked-filled:before {
  content: "\e990";
}
.icon-deezer:before {
  content: "\e991";
}
.icon-facebook:before {
  content: "\e992";
}
.icon-fcbk:before {
  content: "\e992";
}
.icon-google:before {
  content: "\e993";
}
.icon-ggl:before {
  content: "\e993";
}
.icon-instagram:before {
  content: "\e994";
}
.icon-instgrm:before {
  content: "\e994";
}
.icon-linkfire:before {
  content: "\e995";
}
.icon-pandora:before {
  content: "\e996";
}
.icon-pndra:before {
  content: "\e996";
}
.icon-shazam:before {
  content: "\e997";
}
.icon-shzm:before {
  content: "\e997";
}
.icon-snapchat:before {
  content: "\e998";
}
.icon-snpcht:before {
  content: "\e998";
}
.icon-soundcloud:before {
  content: "\e999";
}
.icon-sndcld:before {
  content: "\e999";
}
.icon-tidal:before {
  content: "\e99a";
}
.icon-tdl:before {
  content: "\e99a";
}
.icon-tiktok:before {
  content: "\e99b";
}
.icon-tktk:before {
  content: "\e99b";
}
.icon-twitter:before {
  content: "\e99c";
}
.icon-twttr:before {
  content: "\e99c";
}
.icon-link:before {
  content: "\e99d";
}
.icon-dollar:before {
  content: "\e99e";
}
.icon-fire:before {
  content: "\e99f";
}
.icon-confidential:before {
  content: "\e9a0";
}
.icon-unread:before {
  content: "\e9a1";
}
.icon-thin-plus:before {
  content: "\e9a2";
}
.icon-arrow-link:before {
  content: "\e9a3";
}
.icon-playlisting-wide:before {
  content: "\e9a4";
}
.icon-partial-data:before {
  content: "\e9a5";
}
.icon-track:before {
  content: "\e9a6";
}
.icon-artist-l:before {
  content: "\e9a7";
}
.icon-artist-m:before {
  content: "\e9a8";
}
.icon-profile:before {
  content: "\e9a9";
}
.icon-label:before {
  content: "\e9aa";
}
.icon-unread-m:before {
  content: "\e9ab";
}
.icon-home:before {
  content: "\e9ac";
}
.icon-rounded-play:before {
  content: "\e9ad";
}
.icon-home-m:before {
  content: "\e9ae";
}
.icon-wikipedia:before {
  content: "\e9af";
}
.icon-artist-website:before {
  content: "\e9b0";
}
.icon-lastfm:before {
  content: "\e9b1";
}
.icon-itunes:before {
  content: "\e9b2";
}
.icon-twitch:before {
  content: "\e9b3";
}
.icon-twtch:before {
  content: "\e9b3";
}
.icon-tvmaze:before {
  content: "\e9b4";
}
.icon-tvmz:before {
  content: "\e9b4";
}
.icon-musicbrainz:before {
  content: "\e9b5";
}
.icon-mscbrnz:before {
  content: "\e9b5";
}
.icon-tunefind:before {
  content: "\e9b6";
}
.icon-tnfnd:before {
  content: "\e9b6";
}
.icon-songkick:before {
  content: "\e9b7";
}
.icon-sngkck:before {
  content: "\e9b7";
}
.icon-discogs:before {
  content: "\e9b8";
}
.icon-dscgs:before {
  content: "\e9b8";
}
.icon-genius:before {
  content: "\e9b9";
}
.icon-gns:before {
  content: "\e9b9";
}
.icon-boomplay:before {
  content: "\e9ba";
}
.icon-bmpl:before {
  content: "\e9ba";
}
.icon-bandsintown:before {
  content: "\e9bb";
}
.icon-bndsntwn:before {
  content: "\e9bb";
}
.icon-bookmark:before {
  content: "\e9bc";
}
.icon-categories:before {
  content: "\e9bd";
}
.icon-folder:before {
  content: "\e9be";
}
.icon-search-l:before {
  content: "\e9bf";
}
.icon-menu-l:before {
  content: "\e9c0";
}
.icon-close-l:before {
  content: "\e9c1";
}
.icon-categories-l:before {
  content: "\e9c2";
}
.icon-profile-l:before {
  content: "\e9c3";
}
.icon-rounded-play-m:before {
  content: "\e9c4";
}
.icon-unread-l:before {
  content: "\e9c5";
}
.icon-track-l:before {
  content: "\e9c6";
}
.icon-categories-b:before {
  content: "\e9c7";
}
.icon-warning-l:before {
  content: "\e9c8";
}
.icon-expand-b:before {
  content: "\e9c9";
}
.icon-collapse-b:before {
  content: "\e9ca";
}
.icon-cac:before {
  content: "\e9cb";
}
.icon-envelope:before {
  content: "\e9cc";
}
.icon-male:before {
  content: "\e9cd";
}
.icon-female:before {
  content: "\e9ce";
}
.icon-home-filled-s:before {
  content: "\e9cf";
}
.icon-subscribe-filled-s:before {
  content: "\e9d0";
}
.icon-unsubscribe-filled-s:before {
  content: "\e9d1";
}
.icon-warning-s:before {
  content: "\e9d2";
}
.icon-google-trends:before {
  content: "\e9d3";
}
.icon-new-window-s:before {
  content: "\e9d4";
}
.icon-new-track:before {
  content: "\e9d5";
}
.icon-new-track-l:before {
  content: "\e9d6";
}
.icon-cards:before {
  content: "\e9d7";
}
.icon-delete-inner-outline:before {
  content: "\e9d8";
}
.icon-minus:before {
  content: "\e9d9";
}
.icon-down-single-s:before {
  content: "\e9da";
}
.icon-up-single-s:before {
  content: "\e9db";
}
.icon-label-permissions:before {
  content: "\e9dc";
}
.icon-list-m:before {
  content: "\e9dd";
}
.icon-lock-outlined:before {
  content: "\e9df";
}
.icon-stack-plus:before {
  content: "\e9e0";
}
.icon-preferences:before {
  content: "\e9e1";
}
.icon-copy-outlined:before {
  content: "\e9e2";
}
.icon-share:before {
  content: "\e9e3";
}
.icon-locked-artist:before {
  content: "\e9e4";
}
